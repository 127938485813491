import axiosInstance from '../../../helpers/backend_helper';
import axios from 'axios';

const deblacklistCandidate = async (candidateId) => {
  try {
    console.log('Deblacklisting candidate with ID:', candidateId);
    const response = await axios.post('https://api-stack-api.onrender.com/api/v1/candidates/deblacklistCandidate', {
      id: candidateId
    });
    console.log('Deblacklist response:', response);
    return response.data;
  } catch (error) {
    console.error('Error de-blacklisting candidate:', error);
    throw error;
  }
};

export default deblacklistCandidate;
