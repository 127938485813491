import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import withRouter from "components/Common/withRouter";
import { map } from "lodash";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Card,
  CardBody,
  Input,
} from "reactstrap";

// Import Components
import Breadcrumbs from "components/Common/Breadcrumb";
import ApproveModal from "./ApproveModal";
import DeclineModal from "./DeclineModal";

// Import API Helpers
import getAllUsers from "common/realBackend/users/getAllUsers";
import updateUserAcceptanceStatus from "common/realBackend/users/updateUserAcceptanceStatus";
import getAllContacts from "common/realBackend/blog/getAllContacts";
import deleteContactById from "common/realBackend/blog/deleteContact";  // Import the delete function

// Helper function to format the date to "DD MMM, YYYY"
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

const PendingUsers = () => {
  // Meta title
  document.title = "Contact Messages | AAOOA";

  const [users, setUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);  // New state for delete confirmation modal
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null); // State for selected contact
  const [action, setAction] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getAllUsers();
        const filteredUsers = response.data.filter(
          (user) => user.acceptanceStatus === "pending" || user.acceptanceStatus === "declined"
        );
        setUsers(filteredUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchContacts = async () => {
      try {
        const response = await getAllContacts();
        setContacts(response.data.contacts);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchUsers();
    fetchContacts();
  }, []);

  const handleUserClick = (item, actionType) => {
    console.log("Item clicked:", item);
    if (item.email) {  
      setSelectedContact(item); 
      if (actionType === "delete") {
        setDeleteModal(true);
      }
    } else {
      setSelectedUser(item);
      setAction(actionType);
      if (actionType === "view") {
        console.log("Navigating to message details for user:", item.id);
        navigate(`/message-details/${item.id}`);
      } else {
        setDeclineModal(true);
      }
    }
  };  

  const handleAction = async () => {
    if (selectedUser) {
      try {
        const newStatus = action === "approve" ? "approved" : "declined";
        const response = await updateUserAcceptanceStatus(selectedUser.id, newStatus);

        console.log(`User ${action}d successfully:`, response);

        // Optionally refetch users or update state
        const usersResponse = await getAllUsers();
        const filteredUsers = usersResponse.data.filter(
          (user) => user.acceptanceStatus === "pending" || user.acceptanceStatus === "declined"
        );
        setUsers(filteredUsers);
      } catch (error) {
        console.error(`Error updating user status to ${action}:`, error);
      }
    }
    setApproveModal(false);
    setDeclineModal(false);
  };

  const handleDeleteContact = async () => {
    if (selectedContact) {
      try {
        const response = await deleteContactById(selectedContact.id);
        console.log(`Contact deleted successfully:`, response);

        // Optionally refetch contacts or update state
        const contactsResponse = await getAllContacts();
        setContacts(contactsResponse.data.contacts);
      } catch (error) {
        console.error('Error deleting contact:', error);
      }
    }
    setDeleteModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.fullName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredContacts = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <React.Fragment>
      <ApproveModal
        show={approveModal}
        onApproveClick={handleAction}
        onCloseClick={() => setApproveModal(false)}
      />
      <DeclineModal
        show={declineModal}
        onDeclineClick={handleAction}
        onCloseClick={() => setDeclineModal(false)}
      />
      {/* Delete Confirmation Modal */}
      <DeclineModal
        show={deleteModal}
        onDeclineClick={handleDeleteContact}  // Confirm deletion
        onCloseClick={() => setDeleteModal(false)}
        title="Are you sure you want to delete this contact?"
        buttonText="Delete"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contact Messages" breadcrumbItem="Contact Messages" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Input
                    type="text"
                    placeholder="Search by name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{ width: '300px' }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="table-responsive">
                <Table className="project-list-table table-nowrap align-middle table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Name and Email</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Message</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Combine users and contacts in the same table */}
                    {[...filteredUsers, ...filteredContacts].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <h5 className="text-truncate font-size-14">
                            <Link to="#" className="text-dark">
                              {item.fullName || item.name}
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">{item.email}</p>
                        </td>
                        <td>{item.subject}</td>
                        <td>{item.message}</td>
                        <td>{formatDate(item.createdAt)}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle href="#" className="card-drop" tag="a">
                              <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem>
                              <Link to={`/message-details/${item.id}`}>
                                <i className="mdi mdi-eye font-size-16 text-primary me-1" /> View
                              </Link>
                            </DropdownItem>

                              <DropdownItem
                                href="#"
                                onClick={() => handleUserClick(item, "delete")}
                              >
                                <i className="mdi mdi-delete font-size-16 text-danger me-1" />{" "}
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PendingUsers);
