import axiosInstance from '../../../helpers/backend_helper';

const deleteBookById = async (id) => {
  try {
    const response = await axiosInstance.delete(`api/v1/books/deleteBook/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting book with ID ${id}:`, error);
    throw error;
  }
};

export default deleteBookById;
