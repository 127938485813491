import axiosInstance from '../../../helpers/backend_helper';

const getAllContacts = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/contacts/getAllContacts');
    return response.data;
  } catch (error) {
    console.error('Error fetching all contacts:', error);
    throw error;
  }
};

export default getAllContacts;
