import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Base URL of the API
const baseURL = 'https://bookstory.onrender.com';

// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: baseURL,
});

// Add a request interceptor to attach the token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem('authUser');
    if (user) {
      const token = JSON.parse(user).token;
      config.headers['Authorization'] = `Bearer ${token}`; // Attach token to headers
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 Unauthorized errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response; // If the response is successful, return it
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear the user's data from localStorage
      localStorage.removeItem('authUser');

      // Optionally, navigate to the login page
      // Note: This requires the useNavigate hook, but this code won't work directly here.
      // So you might need to use some kind of global state or event emitter for this part
      // For example, you could store this logic inside a context or state provider
      window.location.href = '/login'; // Redirect to login page

      // Return a rejected promise to ensure the error is propagated
      return Promise.reject(error);
    }
    return Promise.reject(error); // Handle other errors
  }
);

export default axiosInstance;
