import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import getAllSubscribers from "common/realBackend/blog/getAllSubscribers";

const Settings = () => {
  const [subscriberCount, setSubscriberCount] = useState(0);

  useEffect(() => {
    // Fetch subscriber count from the API
    const fetchSubscriberCount = async () => {
      try {
        const response = await getAllSubscribers();
        const subscribers = response.data?.subscribers || []; // Access nested subscribers array
        setSubscriberCount(subscribers.length); // Count of subscribers
      } catch (error) {
        console.error("Error fetching subscriber count:", error);
      }
    };

    fetchSubscriberCount();
  }, []);

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap align-items-start">
              <h5 className="card-title mb-3 me-2">Subscribers</h5>

            </div>

            <div className="d-flex flex-wrap">
              <div>
                <p className="text-muted mb-1">Total Subscribers</p>
                <h4 className="mb-3">{subscriberCount}</h4>
                <p className="text-success mb-0">
                  <span>0 % <i className="mdi mdi-arrow-top-right ms-1"></i></span>
                </p>
              </div>
              <div className="ms-auto align-self-end">
                <i className="bx bx-group display-4 text-light"></i>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Settings;
