import axiosInstance from '../../../helpers/backend_helper';

const updateEpisode = async (id, title, description, type, coverImage, audioLink, videoLinkString) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('storyText', description);
    formData.append('type', type);
    if (coverImage) { // Only append if there's a new cover image
      formData.append('coverImage', coverImage); // Cover image file
    }
    if (audioLink) { // Only append if there's a new audio link
      formData.append('audioLink', audioLink);   // Audio link file
    }
    formData.append('videoLinkString', videoLinkString); // Video link string

    // Update the API URL to point to the episodes endpoint
    const response = await axiosInstance.patch(`/api/v1/books/episodes/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating episode:', error);
    throw error;
  }
};

export default updateEpisode;
