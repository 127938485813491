import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";

// Import the getAllBooks function
import getAllBooks from "common/realBackend/blog/getAllBooks";

const Posts = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [books, setBooks] = useState([]);

  useEffect(() => {
    // Fetch books when the component mounts
    const fetchBooks = async () => {
      try {
        const response = await getAllBooks();
        const allBooks = response.data.books;

        // Sort by createdAt date and take the four most recent books
        const recentBooks = allBooks
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 4);

        setBooks(recentBooks);
      } catch (error) {
        console.error("Error fetching recent books:", error);
      }
    };

    fetchBooks();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Col xl={4} lg={6}>
        <Card>
          <CardHeader className="bg-transparent border-bottom">
            <div className="d-flex flex-wrap">
              <div className="me-2">
                <h5 className="card-title mt-1 mb-0">Recent Stories</h5>
              </div>
              <ul className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto" role="tablist">
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => { toggle("1"); }}
                  >
                    Recent
                  </NavLink>
                </NavItem>
              </ul>
            </div>
          </CardHeader>

          <CardBody>
            <SimpleBar style={{ maxHeight: "295px" }}>
              <div>
                <TabContent activeTab={activeTab}>
                  <TabPane className="show" tabId="1">
                    <ul className="list-group list-group-flush">
                      {books.map(book => (
                        <li key={book.id} className="list-group-item py-3">
                          <div className="d-flex">
                            <div className="me-3">
                              <img
                                src={book.coverImage}
                                alt={book.title}
                                className="avatar-md d-block rounded"
                                style={{
                                  width: "100px",        // Fixed width
                                  height: "50px",       // Fixed height
                                  objectFit: "cover",    // Cover to maintain aspect ratio
                                  borderRadius: "0.25rem" // Maintain rounded corners
                                }}
                              />

                            </div>
                            <div className="align-self-center overflow-hidden me-auto">
                              <div>
                                <h5 className="font-size-14 text-truncate">
                                  <Link to="#" className="text-dark">
                                    {book.title}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  {new Date(book.createdAt).toLocaleDateString()}
                                </p>
                              </div>
                            </div>
                            <UncontrolledDropdown className="ms-2">
                              <DropdownToggle
                                tag="a"
                                className="text-muted font-size-14"
                                color="white"
                                type="button"
                              >
                                <i className="mdi mdi-dots-horizontal"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <Link className="dropdown-item" to="#">
                                  View Details
                                </Link>
                                <Link className="dropdown-item" to="#">
                                  Share
                                </Link>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </TabPane>
                </TabContent>
              </div>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Posts;
