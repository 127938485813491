import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

// Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// Actions
import { editProfile, resetProfileFlag } from "../../store/actions";
// API
import getUserById from "common/realBackend/blog/getUserById";
import updateUser from "common/realBackend/blog/updateUser";

const UserProfile = () => {
  document.title = "Profile | AAOOA Admin";

  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    id: 9,
    profileImageURL: avatar,
  });

  const { error, success } = useSelector((state) => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserById(9);
        const user = response.data;
        setUserData({
          email: user.email || "",
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          role: user.role || "",
          id: user.id || 9,
          profileImageURL: user.profileImageURL || avatar,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();

    if (success) {
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Last Name"),
    }),
    onSubmit: async (values) => {
      try {
        await updateUser(userData.id, values.firstName, values.lastName);
        dispatch(editProfile(values));
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="AAOOA" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && <Alert color="danger">{error}</Alert>}
              {success && <Alert color="success">{success}</Alert>}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={userData.profileImageURL}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userData.firstName} {userData.lastName}</h5>
                        <p className="mb-1">{userData.email}</p>
                        <p className="mb-0">Id no: #{userData.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update Profile Information</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col md="6">
                    <Label className="form-label">First Name</Label>
                    <Input
                      name="firstName"
                      className="form-control"
                      placeholder="Enter First Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                      invalid={
                        validation.touched.firstName && validation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstName && validation.errors.firstName && (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                    )}
                  </Col>

                  <Col md="6">
                    <Label className="form-label">Last Name</Label>
                    <Input
                      name="lastName"
                      className="form-control"
                      placeholder="Enter Last Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                      invalid={
                        validation.touched.lastName && validation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastName && validation.errors.lastName && (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                    )}
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      className="form-control"
                      type="email"
                      value={userData.email || ""}
                      readOnly
                    />
                  </Col>

                  <Col md="6">
                    <Label className="form-label">Role</Label>
                    <Input
                      name="role"
                      className="form-control"
                      type="text"
                      value={userData.role || ""}
                      readOnly
                    />
                  </Col>
                </Row>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Profile
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
