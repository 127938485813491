import axiosInstance from '../../../helpers/backend_helper';

const updateUser = async (id, firstName, lastName) => {
  try {
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);

    const response = await axiosInstance.patch(`/api/v1/admins/updateUser/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export default updateUser;
