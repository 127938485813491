import axiosInstance from '../../../helpers/backend_helper';

const createBook = async (title, description, type, coverImage, audioLink, videoLinkString, storyText) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('storyText', storyText); // Add storyText
    formData.append('type', type);
    formData.append('coverImage', coverImage);
    formData.append('audioLink', audioLink);
    formData.append('videoLinkString', videoLinkString);

    const response = await axiosInstance.post('/api/v1/books/createBook', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error creating book:', error);
    throw error;
  }
};

export default createBook;
