import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Table, Button, Card, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import getAllBookEpisodes from "common/realBackend/blog/getAllBookEpisodes";
import getBookById from "common/realBackend/blog/getBookById"; // Import a function to get book details
import deleteEpisodeById from "common/realBackend/blog/deleteEpisode"; // Import the delete function

const BookEpisodes = () => {
  const { bookId } = useParams(); // Get the bookId from the URL
  const [episodes, setEpisodes] = useState([]);
  const [bookName, setBookName] = useState(""); // State for the book name
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const [modal, setModal] = useState(false); // State for the modal visibility
  const [episodeToDelete, setEpisodeToDelete] = useState(null); // State for the episode to delete
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const response = await getAllBookEpisodes(bookId);
        if (response.status === "success") {
          setEpisodes(response.data.episodes);
        }
      } catch (error) {
        console.error("Error fetching episodes:", error);
      }
    };

    const fetchBookName = async () => {
      try {
        const response = await getBookById(bookId); // Fetch the book details
        if (response.status === "success") {
          setBookName(response.data.book.title); // Set the book name from the response
        }
      } catch (error) {
        console.error("Error fetching book details:", error);
      }
    };

    fetchEpisodes();
    fetchBookName(); // Fetch the book name on component mount
  }, [bookId]);

  const handleViewEpisode = (episodeId) => {
    // Navigate to a detailed view page for an episode (if needed)
    navigate(`/blog-details/series/${bookId}/${episodeId}`);
  };

  const handleUpdateEpisode = (episodeId) => {
    navigate(`/story-update/series/${bookId}/${episodeId}`);
  };

  const toggleModal = () => setModal(!modal);

  const handleDeleteEpisode = async (episodeId) => {
    try {
      const response = await deleteEpisodeById(episodeId);
      if (response.status === "success") {
        // After deleting, refresh the episode list
        setEpisodes(episodes.filter((episode) => episode.id !== episodeId));
        toggleModal(); // Close the modal after successful deletion
      }
    } catch (error) {
      console.error("Error deleting episode:", error);
    }
  };

  // Filter episodes based on the search term
  const filteredEpisodes = episodes.filter((episode) =>
    episode.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Card>
          <h4 className="p-3">Episodes for Book: {bookName || "Loading..."}</h4>
          <div className="search-box mb-4 p-3">
            <input
              type="text"
              className="form-control rounded bg-light border-light"
              placeholder="Search by title..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {filteredEpisodes.length === 0 ? (
            <div className="p-3">No episodes found.</div>
          ) : (
            <Table responsive>
              <thead>
                <tr>
                  <th>Episode</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredEpisodes.map((episode, index) => (
                  <tr key={episode.id}>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        src={episode.coverImage}
                        alt={episode.title}
                        style={{ width: "75px", height: "50px", objectFit: "cover" }}
                      />
                    </td>
                    <td>{episode.title}</td>
                    <td>{new Date(episode.createdAt).toLocaleDateString()}</td>
                    <td>
                      <Button
                        color="link"
                        className="text-primary"
                        onClick={() => handleViewEpisode(episode.id)}
                        title="View"
                      >
                        <i className="mdi mdi-eye font-size-16 text-primary me-1" />
                      </Button>
                      <Button
                        color="link"
                        className="text-warning me-2"
                        onClick={() => handleUpdateEpisode(episode.id)}
                        title="Update"
                      >
                        <i className="mdi mdi-pencil font-size-16 text-warning me-1" />
                      </Button>
                      <Button
                        color="link"
                        className="text-danger"
                        onClick={() => {
                          setEpisodeToDelete(episode.id);
                          toggleModal();
                        }}
                        title="Delete"
                      >
                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card>
      </Col>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this episode?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => handleDeleteEpisode(episodeToDelete)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default BookEpisodes;
