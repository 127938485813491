import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Spinner,
  Alert
} from "reactstrap";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import createBook from "common/realBackend/blog/createBook";
import createEpisode from "common/realBackend/blog/createEpisode";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const TasksCreate = () => {
  const { bookId } = useParams(); // Get the bookId from the URL
  document.title = bookId ? "Create Episode | AAOOA Admin" : "Create Story | AAOOA Admin";

  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [storyTextEditorState, setStoryTextEditorState] = useState(EditorState.createEmpty());

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Story Title is required"),
    type: bookId ? null : Yup.string().required("Story Type is required"),
    description: Yup.string().required("Description is required"),
    storyText: Yup.string().required("Story Text is required"),
    coverImage: Yup.mixed().required("Cover Image is required"),
  });

  const handleEditorChange = (state, setFieldValue, fieldName) => {
    const htmlContent = draftToHtml(convertToRaw(state.getCurrentContent()));
    setFieldValue(fieldName, htmlContent);
    if (fieldName === "storyText") {
      setStoryTextEditorState(state);
    } else {
      setEditorState(state);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log("Form values before submission:", values); // Log form data

    const { title, type, description, storyText, coverImage, audioLink, videoLinkString } = values;
    setLoading(true);
    try {
      if (bookId) {
        await createEpisode(bookId, title, description, storyText, coverImage, audioLink, videoLinkString);
        setAlertMessage("Episode created successfully!");
      } else {
        await createBook(title, description, type, coverImage, audioLink, videoLinkString, storyText);
        setAlertMessage("Story created successfully!");
      }
      setAlertVisible(true);
      resetForm();
      setCoverImagePreview(null);
      setEditorState(EditorState.createEmpty());
      setStoryTextEditorState(EditorState.createEmpty());
    } catch (error) {
      console.error("Error creating:", error);
      setAlertMessage("Error creating. Please try again.");
      setAlertVisible(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Stories" breadcrumbItem={bookId ? "Create Episode" : "Create Story"} />

        {alertVisible && (
          <Alert color={alertMessage.includes("Error") ? "danger" : "success"} 
                 toggle={() => setAlertVisible(false)} 
                 style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 1050 }}>
            {alertMessage}
          </Alert>
        )}

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{bookId ? "Create New Episode" : "Create New Story"}</CardTitle>
                <Formik
                  initialValues={{
                    title: "",
                    type: bookId ? "" : "", // Initial value for type (if necessary)
                    description: "",
                    storyText: "",
                    coverImage: null,
                    audioLink: null,
                    videoLinkString: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue }) => (
                    <Form>
                      <FormGroup className="mb-4">
                        <Label htmlFor="title" className="col-form-label">Story Title</Label>
                        <Field
                          id="title"
                          name="title"
                          as={Input}
                          placeholder="Enter Book Title..."
                        />
                        <ErrorMessage name="title" component="div" className="text-danger" />
                      </FormGroup>

                      {!bookId && (
                        <FormGroup className="mb-4">
                          <Label htmlFor="type" className="col-form-label">Story Type</Label>
                          <Field as="select" name="type" className="form-control">
                            <option value="">Select Story Type...</option>
                            <option value="series">Series</option>
                            <option value="one-short">One-Shot</option>
                          </Field>
                          <ErrorMessage name="type" component="div" className="text-danger" />
                        </FormGroup>
                      )}

                      <FormGroup className="mb-4">
                        <Label htmlFor="description" className="col-form-label">Description</Label>
                        <Field
                          as="textarea"
                          id="description"
                          name="description"
                          className="form-control"
                          placeholder="Enter a short description of the story..."
                          rows="5"
                        />
                        <ErrorMessage name="description" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="col-form-label">Story Text</Label>
                        <Editor
                          editorState={storyTextEditorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Write the main story content here..."
                          onEditorStateChange={(state) => handleEditorChange(state, setFieldValue, "storyText")}
                        />
                        <ErrorMessage name="storyText" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="col-form-label">Cover Image</Label>
                        <Input
                          type="file"
                          className="form-control"
                          onChange={(e) => {
                            const file = e.currentTarget.files[0];
                            setFieldValue("coverImage", file);
                            setCoverImagePreview(URL.createObjectURL(file));
                          }}
                        />
                        {coverImagePreview && (
                          <div className="mt-2">
                            <img src={coverImagePreview} alt="Cover Preview" style={{ maxWidth: '250px', height: 'auto' }} />
                          </div>
                        )}
                        <ErrorMessage name="coverImage" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="col-form-label">Upload Audio</Label>
                        <Input
                          type="file"
                          className="form-control"
                          onChange={(e) => setFieldValue("audioLink", e.currentTarget.files[0])}
                        />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label htmlFor="videoLinkString" className="col-form-label">Video Link</Label>
                        <Field
                          id="videoLinkString"
                          name="videoLinkString"
                          as={Input}
                          placeholder="Enter Video Link..."
                        />
                      </FormGroup>

                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button type="submit" color="primary" disabled={loading}>
                            {loading ? <Spinner size="sm" /> : (bookId ? "Create Episode" : "Create Story")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TasksCreate;
