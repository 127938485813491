import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import getAllUsers from "common/realBackend/blog/getAllUsers";
import deleteUser from "common/realBackend/blog/deleteUser";

const ContactsList1 = () => {
  document.title = "User List | AAOOA Admin";

  const [usersList, setUsersList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Fetch all users and filter out admins
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getAllUsers();
        if (response.status === "success") {
          const filteredUsers = response.data
            .filter(user => user.role !== "admin" && user.role !== "superAdmin")
            .map(user => ({
              ...user,
              fullName: `${user.firstName} ${user.lastName}`,
            }));
          setUsersList(filteredUsers);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const columns = useMemo(() => [
    {
      Header: "#",
      Cell: () => <input type="checkbox" className="form-check-input" />,
    },
    {
      Header: "Username",
      accessor: "userName",
    },
    {
      Header: "Full Name",
      accessor: "fullName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const userData = row.original;
        return (
          <div className="d-flex gap-3">
            <Link to="#" className="text-danger" onClick={() => onClickDelete(userData)}>
              <i className="mdi mdi-delete font-size-18" />
            </Link>
          </div>
        );
      },
    },
  ], []);

  const onClickDelete = user => {
    setSelectedUserId(user.id);
    setDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser(selectedUserId);
      setUsersList(usersList.filter(user => user.id !== selectedUserId));
      setDeleteModal(false);
      toast.success("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={usersList}
                    isGlobalFilter={true}
                    customPageSize={10}
                  />
                  <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)}>
                    <ModalHeader toggle={() => setDeleteModal(false)}>
                      Confirm Delete
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this user?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={handleDeleteUser}>
                        Yes, Delete
                      </Button>
                      <Button color="secondary" onClick={() => setDeleteModal(false)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactsList1;
