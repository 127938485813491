import axiosInstance from '../../../helpers/backend_helper';

const getSingleAmazonBook = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/v1/books/getAmazonBook/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching Amazon book with ID ${id}:`, error);
    throw error;
  }
};

export default getSingleAmazonBook;
