import axiosInstance from '../../../helpers/backend_helper';

const getAllSubscribers = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/subscribers');
    return response.data;
  } catch (error) {
    console.error('Error fetching all subscribers:', error);
    throw error;
  }
};

export default getAllSubscribers;
