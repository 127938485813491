import axiosInstance from '../../../helpers/backend_helper';

const getEpisodeById = async (bookId, episodeId) => {
  try {
    const response = await axiosInstance.get(`/api/v1/books/${bookId}/episodes/${episodeId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching episode with book ID ${bookId} and episode ID ${episodeId}:`, error);
    throw error;
  }
};

export default getEpisodeById;
