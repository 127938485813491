import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

// Import API
import getAllContacts from "common/realBackend/blog/getAllContacts"; // New import

const ContactsList = (props) => {
  // Meta title
  document.title = "Contact List | Skote - React Admin & Dashboard Template";

  const [contact, setContact] = useState({});
  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (contact && contact.name) || "",
      email: (contact && contact.email) || "",
      subject: (contact && contact.subject) || "",
      message: (contact && contact.message) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().email("Invalid email").required("Please Enter Email"),
      subject: Yup.string().required("Please Enter Subject"),
      message: Yup.string().required("Please Enter Message"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updatedContact = {
          id: contact.id,
          name: values.name,
          email: values.email,
          subject: values.subject,
          message: values.message,
        };
        // Update contact logic here
        console.log("Updating contact:", updatedContact);
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newContact = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values.name,
          email: values.email,
          subject: values.subject,
          message: values.message,
        };
        // Save new contact logic here
        console.log("Adding new contact:", newContact);
        validation.resetForm();
      }
      toggle();
    },
  });

  // Fetch contacts from API
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await getAllContacts();
        console.log("Fetched contacts:", response);

        const contacts = response.data.contacts;

        const mappedData = contacts.map(contact => ({
          id: contact.id,
          name: contact.name,
          email: contact.email,
          subject: contact.subject,
          message: contact.message,
          createdAt: new Date(contact.createdAt).toLocaleString(), // Format the date as needed
        }));

        setUserList(mappedData);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Subject",
        accessor: "subject",
      },
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const contactData = cellProps.row.original;
                  handleUserClick(contactData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const contactData = cellProps.row.original;
                  onClickDelete(contactData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const contact = arg;
    setContact({
      id: contact.id,
      name: contact.name,
      email: contact.email,
      subject: contact.subject,
      message: contact.message,
    });
    setIsEdit(true);
    toggle();
  };

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  const handleDeleteContact = async () => {
    if (contact.id) {
      try {
        // Call delete API logic here
        // await deleteContact(contact.id);
        setUserList((prevUserList) =>
          prevUserList.filter((user) => user.id !== contact.id)
        );
        setDeleteModal(false);
      } catch (error) {
        console.error("Error deleting contact:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumbs title="Contact" breadcrumbItem="Contact List" />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center">
                  <h4 className="card-title mb-4 flex-grow-1">Contact List</h4>
                  <div className="flex-shrink-0">
                    <div className="d-flex gap-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setIsEdit(false);
                          toggle();
                        }}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Add New Contact
                      </button>
                    </div>
                  </div>
                </div>

                <TableContainer
                  columns={columns}
                  data={userList}
                  isGlobalFilter={true}
                  customPageSize={10}
                  className="custom-header-css"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteContact}
        onCloseClick={() => setDeleteModal(false)}
      />
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
