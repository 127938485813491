import axiosInstance from '../../../helpers/backend_helper';

const getContactById = async (id) => {
  try {
    const response = await axiosInstance.get(`api/v1/contacts/getContact/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching contact with ID ${id}:`, error);
    throw error;
  }
};

export default getContactById;
