import React from "react";
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import BlogEpisiodes from "./BlogEpisode"
import RightBar from "./RightBar"

const Index = props => {
    //meta title
    document.title="Episodes | AAOOA";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Stories" breadcrumbItem="Episodes" />
          <Row>
            <BlogEpisiodes />
            <RightBar />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index