import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Spinner,
  Alert
} from "reactstrap";
import { useParams } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import updateEpisode from "common/realBackend/blog/updateEpisode"; // Change from updateBook to updateEpisode

import updateBook from "common/realBackend/blog/updateBook";

import getBookById from "common/realBackend/blog/getBlogById"; // Modify to get story by ID
import getEpisodeById from "common/realBackend/blog/getEpisodeById"; // New function to fetch episode by ID
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const StoryUpdate = ({ onUpdateSuccess }) => {
  const { type, id, episodeId } = useParams(); // Destructure to get type, id, and episodeId
  const [existingStory, setExistingStory] = useState(null);
  const [existingEpisode, setExistingEpisode] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // Validation schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Story Title is required"),
    //type: Yup.string().required("Story Type is required"),
    storyText: Yup.string().required("Story Text is required"), // Change validation to 'storyText'
    coverImage: Yup.mixed().nullable(),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (episodeId) {
          // Fetch episode details if episodeId is provided
          const episodeData = await getEpisodeById(id, episodeId);
          setExistingEpisode(episodeData.data.episode);
        } else {
          // Otherwise, fetch story details
          const storyData = await getBookById(id);
          setExistingStory(storyData.data.book);
        }

        // Set cover image preview if it exists
        const coverImage = episodeId ? existingEpisode?.coverImage : existingStory?.coverImage;
        if (coverImage) setCoverImagePreview(coverImage);
      } catch (error) {
        console.error("Error fetching story/episode data:", error);
      }
    };
    fetchData();
  }, [id, episodeId, existingEpisode, existingStory]);

  const handleSubmit = async (values) => {
    const { title, type, coverImage, audioLink, videoLinkString, storyText } = values; // Use 'storyText' here

    setLoading(true);
    try {
      let response;
      if (episodeId) {
        // Update episode
        response = await updateEpisode(episodeId, title, storyText, type, coverImage, audioLink, videoLinkString); // Pass 'storyText'
      } else {
        // Update story
        response = await updateBook(id, title, storyText, type, coverImage, audioLink, videoLinkString); // Pass 'storyText'
      }

      setAlertMessage("Updated successfully!");
      setAlertVisible(true);
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (error) {
      setAlertMessage("Error updating. Please try again.");
      setAlertVisible(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Stories" breadcrumbItem={episodeId ? "Update Episode" : "Update Story"} />

        {alertVisible && (
          <Alert color={alertMessage.includes("Error") ? "danger" : "success"}
                 toggle={() => setAlertVisible(false)}
                 style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 1050 }}>
            {alertMessage}
          </Alert>
        )}

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{episodeId ? "Update Episode" : "Update Story"}</CardTitle>
                {(existingStory || existingEpisode) ? (
                  <Formik
                    initialValues={{
                      title: episodeId ? existingEpisode?.title : existingStory?.title || "",
                      type: episodeId ? existingEpisode?.type : existingStory?.type || "",
                      storyText: episodeId ? existingEpisode?.storyText : existingStory?.storyText || "", // Use 'storyText' instead of 'description'
                      coverImage: null,
                      audioLink: episodeId ? existingEpisode?.audioLink : existingStory?.audioLink || null,
                      videoLinkString: episodeId ? existingEpisode?.videoLinkString : existingStory?.videoLinkString || "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    {({ setFieldValue, values }) => (
                      <Form>
                        <FormGroup className="mb-4">
                          <Label htmlFor="title" className="col-form-label">Story/Episode Title</Label>
                          <Field id="title" name="title" as={Input} placeholder="Enter Title..." />
                          <ErrorMessage name="title" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label htmlFor="type" className="col-form-label">Story Type</Label>
                          <Field as="select" name="type" className="form-control">
                            <option value="">Select Story Type...</option>
                            <option value="series">Series</option>
                            <option value="one-short">One-Shot</option>
                          </Field>
                          <ErrorMessage name="type" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label className="col-form-label">Story Text</Label>
                          <Field
                            as="textarea"
                            className="form-control"
                            rows="10"
                            placeholder="Enter story text..."
                            name="storyText" // Formik will handle value and changes for 'storyText'
                          />
                          <ErrorMessage name="storyText" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label className="col-form-label">Cover Image</Label>
                          <Input
                            type="file"
                            className="form-control"
                            onChange={(e) => {
                              const file = e.currentTarget.files[0];
                              setFieldValue("coverImage", file);
                              setCoverImagePreview(URL.createObjectURL(file));
                            }}
                          />
                          {coverImagePreview && (
                            <div className="mt-2">
                              <img src={coverImagePreview} alt="Cover Preview" style={{ maxWidth: '250px', height: 'auto' }} />
                            </div>
                          )}
                          <ErrorMessage name="coverImage" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label className="col-form-label">Current Audio</Label>
                          {episodeId && existingEpisode?.audioLink ? (
                            <audio controls src={existingEpisode.audioLink} style={{ width: '100%' }} />
                          ) : (existingStory?.audioLink && !episodeId) && (
                            <audio controls src={existingStory.audioLink} style={{ width: '100%' }} />
                          )}
                          <Input
                            type="file"
                            className="form-control"
                            onChange={(e) => setFieldValue("audioLink", e.currentTarget.files[0])}
                          />
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label htmlFor="videoLinkString" className="col-form-label">Video Link</Label>
                          <Field id="videoLinkString" name="videoLinkString" as={Input} placeholder="Enter Video Link..." />
                        </FormGroup>

                        <Row className="justify-content-end">
                          <Col lg="10">
                            <Button type="submit" color="primary" disabled={loading}>
                              {loading ? <Spinner size="sm" /> : "Update Story/Episode"}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <Spinner color="primary" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StoryUpdate;