import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { useSelector, useDispatch } from 'react-redux';
import { dashboardBlogVisitorData } from '../../store/actions';
import getAllBooks from "common/realBackend/blog/getAllBooks";

const CardUser = ({ dataColors }) => {
  const apexCardUserChartColors = getChartColorsArray(dataColors);
  const [duration, setDuration] = useState("year");
  const [oneShotCount, setOneShotCount] = useState(0); // Count of one-shots
  const [seriesCount, setSeriesCount] = useState(0); // Count of series
  const dispatch = useDispatch();

  const visitorDurationData = (duration) => {
    setDuration(duration);
    dispatch(dashboardBlogVisitorData(duration));
  };

  useEffect(() => {
    dispatch(dashboardBlogVisitorData("year"));

    // Fetch all books and calculate counts for one-shots and series
    const fetchTotalPosts = async () => {
      try {
        const response = await getAllBooks();
        const books = response.data.books;

        // Calculate counts
        const oneShotBooks = books.filter(book => book.type === "one-short");
        const seriesBooks = books.filter(book => book.type === "series");

        setOneShotCount(oneShotBooks.length);
        setSeriesCount(seriesBooks.length);
      } catch (error) {
        console.error('Error fetching book counts:', error);
      }
    };

    fetchTotalPosts();
  }, [dispatch]);

  const { visitor } = useSelector(state => ({
    visitor: state.DashboardBlog.visitor
  }));

  // Set the visitor data to 0
  const series = [
    {
      name: "Current",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "Previous",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: (visitor.categories || []),
    },
    markers: {
      size: 3,
      strokeWidth: 3,
      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  };

  return (
    <React.Fragment>
      <Col xl={8}>
        <Row>
          <Col lg={6}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Total One-Shots</p>
                    <h5 className="mb-0">{oneShotCount}</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bxs-book-bookmark"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Total Series</p>
                    <h5 className="mb-0">{seriesCount}</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bxs-book-bookmark"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </Col>
    </React.Fragment>
  );
};

CardUser.propTypes = {
  dataColors: PropTypes.array.isRequired
};

export default CardUser;
