import axiosInstance from '../../../helpers/backend_helper';

const getAbout = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/abouts');
    return response.data;
  } catch (error) {
    console.error('Error fetching About data:', error);
    throw error;
  }
};

export default getAbout;
