import React from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { Link, useParams } from "react-router-dom"; // Import useParams to get the bookId

const RightBar = () => {
  const { bookId } = useParams(); // Get the bookId from the URL

  return (
    <React.Fragment>
      <Col xl={3} lg={4}>
        <Card>
          <CardBody className="p-4">
            <div className="d-flex justify-content-between mb-4">
              {/* Dynamically link to /tasks-create/:bookId */}
              <Button color="primary" tag={Link} to={`/tasks-create/${bookId}`}>
                Create Episode
              </Button>
            </div>

            <hr className="my-4" />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightBar;
