import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import getAllAmazonBooks from "common/realBackend/blog/getAllAmazonBooks";
import deleteAmazonBookById from "common/realBackend/blog/deleteAmazonBook"; // Correct import for delete function

const BookList = () => {
  document.title = "Book List | AAOOA Admin";

  const [booksList, setBooksList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState(null);

  // Fetch all books
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await getAllAmazonBooks();
        if (response.status === "success") {
          setBooksList(response.data.amazonBook);
        }
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };
  
    fetchBooks();
  }, []);

  const columns = useMemo(() => [
    {
      Header: "#",
      Cell: () => <input type="checkbox" className="form-check-input" />,
    },
    {
      Header: "Book Title",
      accessor: "title",
    },
    {
      Header: "Book Image",
      accessor: "coverImage",
      Cell: ({ value }) => (
        <img src={value} alt="Book" style={{ width: '50px', height: 'auto' }} />
      ),
    },
    {
      Header: "Book Link",
      accessor: "amazonLink",
      Cell: ({ value }) => <Link to={value} target="_blank">View Book</Link>,
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const bookData = row.original;
        return (
          <div className="d-flex gap-3">
            <Link
              to={`/amazon-book-update/${bookData.id}`}
              className="text-primary"
            >
              <i className="mdi mdi-pencil font-size-18" />
            </Link>
            <Link to="#" className="text-danger" onClick={() => onClickDelete(bookData)}>
              <i className="mdi mdi-delete font-size-18" />
            </Link>
          </div>
        );
      },
    },
  ], []);

  const onClickDelete = book => {
    setSelectedBookId(book.id);
    setDeleteModal(true);
  };

  const handleDeleteBook = async () => {
    try {
      await deleteAmazonBookById(selectedBookId);
      setBooksList(booksList.filter(book => book.id !== selectedBookId));
      setDeleteModal(false);
      toast.success("Book deleted successfully!");
    } catch (error) {
      console.error("Error deleting book:", error);
      toast.error("Error deleting book. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={booksList}
                    isGlobalFilter={true}
                    customPageSize={10}
                  />
                  <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)}>
                    <ModalHeader toggle={() => setDeleteModal(false)}>
                      Confirm Delete
                    </ModalHeader>
                    <ModalBody>
                      Are you sure you want to delete this book?
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={handleDeleteBook}>
                        Yes, Delete
                      </Button>
                      <Button color="secondary" onClick={() => setDeleteModal(false)}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BookList;
