import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../../components/Common/DeleteModal";
import getAllSubscribers from "common/realBackend/blog/getAllSubscribers";
import deleteSubscriberById from "../../../helpers/backend_helper";  // Import the delete function

// redux
import { useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';

// Import date-fns for date formatting
import { format } from "date-fns";

// Subscriber Column
import {
  UserName,
  PhoneEmail,
  SubscriptionDate,
} from './EcommerceCustCol'; // Create the columns for displaying subscriber info

const EcommerceCustomers = props => {

  //meta title
  document.title = "Subscribers | AAOOA Admin";

  const dispatch = useDispatch();

  const [subscribers, setSubscribers] = useState([]); // State for subscribers data
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [subscriber, setSubscriber] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);  // New state to control the delete confirmation modal
  const [selectedSubscriberId, setSelectedSubscriberId] = useState(null);  // State to store selected subscriber ID for deletion

  // Fetch subscribers data on component mount
  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const data = await getAllSubscribers();
        if (data.status === "success") {
          setSubscribers(data.data.subscribers);
        }
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      }
    };

    fetchSubscribers();
  }, []);

  // validation (same as your original form validation)
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: (subscriber && subscriber.email) || '',
      firstName: (subscriber && subscriber.firstName) || '',
      isActive: (subscriber && subscriber.isActive) || true,
      subscribedAt: (subscriber && subscriber.subscribedAt) || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ).required("Please Enter Subscriber's Email"),
      firstName: Yup.string().required("Please Enter Subscriber's Name"),
      isActive: Yup.bool().required("Please Select Subscription Status"),
      subscribedAt: Yup.string().required("Please Enter Subscription Date"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        // update subscriber logic
      } else {
        // save new subscriber logic
      }
      toggle();
    },
  });

  const toggle = () => {
    if (modal) {
      setModal(false);
      setSubscriber(null);
    } else {
      setModal(true);
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDelete = async () => {
    try {
      // Perform the delete operation
      await deleteSubscriberById(selectedSubscriberId);
      
      // Close the delete confirmation modal and update the subscriber list
      setDeleteModal(false);
      setSubscribers(subscribers.filter(sub => sub.id !== selectedSubscriberId));
      
      // Reset the selected subscriber ID
      setSelectedSubscriberId(null);
    } catch (error) {
      console.error("Error deleting subscriber:", error);
    }
  };
  
  // Trigger delete modal on Delete click
  const handleDeleteClick = (subscriberId) => {
    setSelectedSubscriberId(subscriberId); // Set the subscriber ID to be deleted
    toggleDeleteModal(); // Open the delete modal
  };  

  // Custom Subscriber Column
  const columns = useMemo(() => [
    {
      Header: '#',
      Cell: () => <input type="checkbox" className="form-check-input" />,
    },
    {
      Header: 'Name',
      accessor: 'firstName',
      filterable: true,
      Cell: (cellProps) => <UserName {...cellProps} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
      filterable: true,
      Cell: (cellProps) => <PhoneEmail {...cellProps} />,
    },
    {
      Header: 'Subscription Date',
      accessor: 'subscribedAt',
      Cell: (cellProps) => <FormattedSubscriptionDate {...cellProps} />,
    },    
    {
      Header: 'Status',
      accessor: 'isActive',
      Cell: (cellProps) => <SubscriberStatus {...cellProps} />,
    },
    {
      Header: 'Action',
      Cell: (cellProps) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle tag="a" href="#" className="card-drop">
              <i className="mdi mdi-dots-horizontal font-size-18"></i>
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {/* Remove the Edit option */}
              <DropdownItem
                href="#"
                onClick={() => {
                  const subscriberData = cellProps.row.original;
                  setSubscriber(subscriberData);
                  setIsEdit(true);
                  toggle();
                }}
              >
                <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                Delete
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ], []);

  return (
    <React.Fragment>
      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Delete Confirmation</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete this subscriber?</p>
          <div className="text-center">
            <button className="btn btn-danger" onClick={handleDelete}>
              Yes, Delete
            </button>
            <button className="btn btn-secondary" onClick={toggleDeleteModal}>
              Cancel
            </button>
          </div>
        </ModalBody>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Subscribers" breadcrumbItem="Subscribers" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={subscribers} // Pass the subscribers data to the table
                    isGlobalFilter={true}
                    isAddCustList={true}
                    handleCustomerClick={() => toggle()} // Modify as needed
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceCustomers.propTypes = {
  subscribers: PropTypes.array,
  onGetSubscribers: PropTypes.func,
  onAddNewSubscriber: PropTypes.func,
  onDeleteSubscriber: PropTypes.func,
  onUpdateSubscriber: PropTypes.func,
};

export default EcommerceCustomers;

// Date formatting helper component
const FormattedSubscriptionDate = ({ value }) => {
  // Check if value exists and format the date
  return value ? format(new Date(value), 'dd MMM, yyyy') : "N/A";  // Return N/A if value is empty
};

// Status column handling (Renamed from JoiningDate to SubscriberStatus)
const SubscriberStatus = ({ value }) => {
  return value ? "Active" : "Inactive";  // Replace "Active" / "Inactive" with your status logic if needed
};
