import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import getAbout from "common/realBackend/blog/getAbout";
import updateAbout from "common/realBackend/blog/updateAbout";

const AboutUpdate = () => {
  document.title = "Update About | AAOOA Admin";

  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("success");
  const [aboutData, setAboutData] = useState(null);
  const [aboutMeImagePreviews, setAboutMeImagePreviews] = useState([null, null]);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const { data } = await getAbout();
        const socialLinks = JSON.parse(data.about.socialNetworkLink || "{}");
        setAboutData({
          ...data.about,
          socialNetworkLink: {
            linkedin: socialLinks.linkedin || "",
            youtube: socialLinks.youtube || "",
          },
        });

        setAboutMeImagePreviews([
          data.about.aboutMeImages || null,
          data.about.aboutMeImageOne || null,
        ]);
      } catch (error) {
        console.error("Error fetching about data:", error);
        setAlertMessage("Error fetching data. Please try again.");
        setAlertColor("danger");
        setAlertVisible(true);
      }
    };

    fetchAboutData();
  }, []);

  const validationSchema = Yup.object().shape({
    content: Yup.string().required("Content is required"),
    linkedinLink: Yup.string().url("Enter a valid URL for LinkedIn").nullable(),
    youtubeLink: Yup.string().url("Enter a valid URL for YouTube").nullable(),
  });

  const handleSubmit = async (values) => {
    const { content, aboutMeImage1, aboutMeImage2, linkedinLink, youtubeLink } = values;
    setLoading(true);

    const socialNetworkLink = {
      linkedin: linkedinLink || aboutData?.socialNetworkLink?.linkedin || '',
      youtube: youtubeLink || aboutData?.socialNetworkLink?.youtube || '',
    };

    try {
      await updateAbout(
        content,
        aboutMeImage1,
        aboutMeImage2,
        socialNetworkLink,
        aboutData
      );
      setAlertMessage("About section updated successfully!");
      setAlertColor("success");
      setAlertVisible(true);
    } catch (error) {
      console.error("Error updating about section:", error);
      setAlertMessage("Error updating. Please try again.");
      setAlertColor("danger");
      setAlertVisible(true);
    } finally {
      setLoading(false);
    }
  };

  if (!aboutData) {
    return (
      <div className="text-center">
        <Spinner />
        <p>Loading data...</p>
      </div>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="About" breadcrumbItem="Update About" />

        {alertVisible && (
          <Alert
            color={alertColor}
            toggle={() => setAlertVisible(false)}
            style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 1050 }}
          >
            {alertMessage}
          </Alert>
        )}

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Update About Section</CardTitle>
                <Formik
                  initialValues={{
                    content: aboutData.content || '',
                    linkedinLink: aboutData.socialNetworkLink?.linkedin || '',
                    youtubeLink: aboutData.socialNetworkLink?.youtube || '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue }) => (
                    <Form>
                      <FormGroup className="mb-4">
                        <Label htmlFor="content" className="col-form-label">Content</Label>
                        <Field name="content" as="textarea" className="form-control" placeholder="Place Your Content Here..." rows="5" />
                        <ErrorMessage name="content" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="col-form-label">LinkedIn Link</Label>
                        <Field name="linkedinLink" type="url" className="form-control" />
                        <ErrorMessage name="linkedinLink" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="col-form-label">YouTube Link</Label>
                        <Field name="youtubeLink" type="url" className="form-control" />
                        <ErrorMessage name="youtubeLink" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="col-form-label">About Me Image 1</Label>
                        <Input
                          type="file"
                          className="form-control"
                          onChange={(e) => {
                            const file = e.currentTarget.files[0];
                            setFieldValue("aboutMeImage1", file);
                            setAboutMeImagePreviews([URL.createObjectURL(file), aboutMeImagePreviews[1]]);
                          }}
                        />
                        {aboutMeImagePreviews[0] && (
                          <div className="mt-2">
                            <img src={aboutMeImagePreviews[0]} alt="About Me Image 1 Preview" style={{ maxWidth: '250px', height: 'auto' }} />
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="col-form-label">About Me Image 2</Label>
                        <Input
                          type="file"
                          className="form-control"
                          onChange={(e) => {
                            const file = e.currentTarget.files[0];
                            setFieldValue("aboutMeImage2", file);
                            setAboutMeImagePreviews([aboutMeImagePreviews[0], URL.createObjectURL(file)]);
                          }}
                        />
                        {aboutMeImagePreviews[1] && (
                          <div className="mt-2">
                            <img src={aboutMeImagePreviews[1]} alt="About Me Image 2 Preview" style={{ maxWidth: '250px', height: 'auto' }} />
                          </div>
                        )}
                      </FormGroup>

                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button type="submit" color="primary" disabled={loading}>
                            {loading ? <Spinner size="sm" /> : "Update About"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUpdate;
