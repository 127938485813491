import axiosInstance from '../../../helpers/backend_helper';

const updateAmazonBook = async (id, title, description, amazonLink, amazonLinkTwo, coverImage) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('amazonLink', amazonLink);
    formData.append('amazonLinkTwo', amazonLinkTwo); // Include the second Amazon link
    if (coverImage) {
      formData.append('coverImage', coverImage);
    }

    const response = await axiosInstance.patch(`/api/v1/books/updateAmazonBook/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating Amazon book:', error);
    throw error;
  }
};

export default updateAmazonBook;
