import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Collapse, Label, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


//import images
import { candidate } from 'common/data/users'

const AboutUs1 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [selectDate, setSelectDate] = useState();
    const dateChange = (date) => {
        setSelectDate(date)
    };

    return (
        <React.Fragment>
            <Col lg={9}>
            <Row>
                        <Col lg={12}>
                            <Card className="job-filter">
                                <CardBody>
                                    <form action="#">
                                        <Row className="g-3">
                                            <Col xxl={4} lg={4}>
                                                <div className="position-relative">
                                                    <Input type="text" className="form-control" id="searchJob" autoComplete="off" placeholder="Search your candidate" />
                                                </div>
                                            </Col>

                                            <Col xxl={2} lg={4}>
                                                <div className="position-relative">
                                                    <select className="form-select select2" aria-label="Default select example">
                                                        <option defaultValue>State</option>
                                                        <option value="Freelance">Abia</option>
                                                        <option value="Full Time">Adamawa</option>
                                                        <option value="Part Time">Akwa Ibom</option>
                                                        <option value="Internship">Anambra</option>
                                                    </select>
                                                </div>
                                            </Col>


                                            <Col xxl={2} lg={4}>
                                                <div className="position-relative">
                                                    <select className="form-select select2" aria-label="Default select example">
                                                        <option defaultValue>LGA</option>
                                                        <option value="Freelance">Alimosho</option>
                                                        <option value="Full Time">Akowonjo</option>
                                                        <option value="Part Time">Ilesa West</option>
                                                        <option value="Internship">Ikirun</option>
                                                    </select>
                                                </div>
                                            </Col>

                                            <Col xxl={2} lg={4}>
                                                <div className="position-relative">
                                                    <select className="form-select select2" aria-label="Default select example">
                                                        <option defaultValue>Job Category</option>
                                                        <option value="Freelance">Personal Assistant</option>
                                                        <option value="Full Time">Housekeeper</option>
                                                        <option value="Part Time">Cook</option>
                                                        <option value="Internship">Chef</option>
                                                    </select>
                                                </div>
                                            </Col>

                                            <Col xxl={2} lg={6}>
                                                <div className="position-relative">
                                                    <div id="datepicker1">
                                                        <DatePicker
                                                            className="form-control"
                                                            placeholderText="Select date"
                                                            selected={selectDate}
                                                            onChange={dateChange}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                <Row>
                    <Col lg={12}>
                        <h5 className="mb-3">Candidates (8)</h5>
                    </Col>
                    {(Candidate || []).map((item , key) => (
                <Col xl={3} key={key}>
                    <Card>
                        <CardBody>
                            <div className="d-flex align-start mb-3">
                                <div className="flex-grow-1">
                                <span className={
                                        item.type === "Available" ? "badge badge-soft-success" :
                                        item.type === "Unavailable" ? "badge badge-soft-danger" :
                                        "badge badge-soft-warning"  // Fallback for any other types
                                        }>
                                        {item.type}
                                        </span>
                                </div>
                                <button type="button" className="btn btn-light btn-sm like-btn" onClick={(e) => activeBtn(e.target)}>
                                    <i className="bx bx-heart"></i>
                                </button>
                            </div>
                            <div className="text-center mb-3">
                                <img src={item.img} alt="" className="avatar-sm rounded-circle" />
                                <h6 className="font-size-15 mt-3 mb-1">{item.name}</h6>
                                {/* <p className="mb-0 text-muted">{item.designation}</p> */}
                            </div>
                            <div className="d-flex justify-content-between text-muted mb-3">
                                <div className="text-center">
                                    <h6>Experience</h6>
                                    <div className="d-flex justify-content-center gap-2">
                                        <div>
                                            <i className='bx bx-map align-middle text-primary'></i> 5 Years
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <h6>Salary</h6>
                                    <div className="d-flex justify-content-center gap-2">
                                        <p className="mb-0">
                                            <i className='bx bx-money align-middle text-primary'></i> ₦50k - ₦100k
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="hstack gap-2 justify-content-center">
                                {(item.skills || []).map((subItem , key) => (
                                <span key={key} className="badge badge-soft-secondary">{subItem}</span>
                                ))}
                            </div> */}

                            <div className="mt-4 pt-1">
                                <Link to="/candidate-overview" className="btn btn-soft-primary d-block">View Profile</Link>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                ))}
                </Row>

            </Col>
        </React.Fragment>
    );
}

export default AboutUs1;