import axiosInstance from '../../../helpers/backend_helper';

const getAllBooks = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/books/getAllBooks');
    return response.data;
  } catch (error) {
    console.error('Error fetching all blogs:', error);
    throw error;
  }
};

export default getAllBooks;
