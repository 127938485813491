import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, CardBody, Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import img1 from "../../assets/images/small/img-2.jpg"; // Default image (use an appropriate image for contact messages)
import getContactById from "common/realBackend/blog/getContactById"; // Assuming this API exists

const ContactMessageDetails = () => {
  document.title = "Contact Message Details | AAOOA Admin";
  const [content, setContent] = useState(null);
  const { id } = useParams(); // Assuming only the contact message ID is needed

  // Log useParams value to verify
  console.log("id:", id);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const data = await getContactById(id); // Use the getContactById API
        console.log("Fetched Contact Data:", data); // Log the fetched data
        setContent(data.data.contact); // Adjusting to match the API response structure
      } catch (error) {
        console.error(`Error fetching contact with ID ${id}:`, error);
      }
    };
    fetchContent();
  }, [id]);

  if (!content) {
    return <p>Loading...</p>;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contact Message" breadcrumbItem="Details" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div className="text-center">
                          <h4>{content.subject}</h4>
                          <p className="text-muted mb-4">
                            <i className="mdi mdi-calendar me-1"></i> {new Date(content.createdAt).toLocaleDateString()}
                          </p>
                          {/* Display sender's name and email */}
                          <p className="text-muted">
                            <strong>From:</strong> {content.name} <br />
                            <strong>Email:</strong> <a href={`mailto:${content.email}`}>{content.email}</a>
                          </p>
                        </div>

                        <hr />

                        <div className="mt-4">
                          <div className="text-muted font-size-14">
                            <p style={{ lineHeight: "2" }}>{content.message}</p>
                          </div>

                          {/* If audio link is provided */}
                          {content.audioLink && (
                            <div className="mt-4">
                              <audio controls>
                                <source src={content.audioLink} type="audio/mpeg" />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          )}

                          {/* If video link is provided */}
                          {content.videoLink && (
                            <div className="mt-4">
                              <iframe
                                width="300" // Adjust width as needed
                                height="200" // Adjust height as needed
                                src={`https://www.youtube.com/embed/${content.videoLink}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactMessageDetails;
