import axiosInstance from '../../../helpers/backend_helper';

const updateAbout = async (content, aboutMeImages, aboutMeImageOne, socialNetworkLink, existingData) => {
  try {
    const formData = new FormData();

    // Append content
    formData.append('content', content || existingData.content);

    // Append aboutMeImages if available
    if (aboutMeImages) {
      formData.append('aboutMeImages', aboutMeImages);
    }

    // Append aboutMeImageOne if available
    if (aboutMeImageOne) {
      formData.append('aboutMeImageOne', aboutMeImageOne);
    }

    // Append social media links as a JSON string
    const socialLinks = {
      facebook: socialNetworkLink.facebook || existingData.socialNetworkLink?.facebook || '',
      twitter: socialNetworkLink.twitter || existingData.socialNetworkLink?.twitter || '',
      linkedin: socialNetworkLink.linkedin || existingData.socialNetworkLink?.linkedin || '',
      youtube: socialNetworkLink.youtube || existingData.socialNetworkLink?.youtube || ''
    };
    formData.append('socialNetworkLink', JSON.stringify(socialLinks));

    // Send the request
    const response = await axiosInstance.post('/api/v1/abouts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log("Response from server:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating about:', error);
    throw error;
  }
};

export default updateAbout;
